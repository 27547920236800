<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{pageName}}</h1>
    </div>
    <div v-if="$hasPermissions(clientSession, ['ASSET_REPORT'], 1)" style="max-width: 95%; margin: auto; overflow: hidden; padding: 1rem;" id="body-content-area">
      <div class="jobs-container" style="display: flex; flex-direction: column;">
        <div class="jobs-container-tabs">
            <div class="jobs-container-tabs--tab" :class="{ 'is-active': reportType === 'jobs' }" @click="reportType = 'jobs'">
              <span>Jobs</span>
            </div>
            <div class="jobs-container-tabs--tab" :class="{ 'is-active': reportType === 'Salesforce' }" @click="reportType = 'Salesforce'">
              <span>Salesforce</span>
            </div>

            <div class="jobs-container-tabs--tools">
              <a href="javascript:void()" @click="doRefresh">
                <i class="fas fa-sync-alt"></i>
              </a>
              <IconDropdown
                v-if="reportType === 'Salesforce' && $hasPermissions(clientSession, ['ASSET_REPORT', 2])"
                :disabled="disableNewJobs"
                icon-class="fa-plus"
                :options="importOptions"
              />
              <!-- <a
                :disabled="disableNewJobs"
                v-if="reportType === 'Salesforce' && $hasPermissions(clientSession, ['ASSET_REPORT', 2])"
                @click.prevent="disableNewJobs ? null : performNewRequest()"
              >
                <i class="fas fa-plus"></i>
              </a> -->
            </div>
          </div>
          <div class="jobs-container--content">
            <div class="jobs-container-content-search" v-if="reportType === 'jobs'">
              <input class="input" type="text" placeholder="Search" v-model="search" style="max-width: calc(100% - 210px !important);" @keydown.enter="performSearch()"/>
              <div class="control">
                <Select
                  :buttonText="selectedFilter"
                  :options="compFilterOptions"
                  style="width: 200px; margin-left: 10px; z-index: 1000;"
                />
              </div>
              <button class="btn btn-info" style="width: 200px; margin-left: 10px; display: flex; align-items: center;" @click="performSearch()">
                <span style="width: 25px">
                  <i class="fa fa-search"></i>
                </span>
                <span style="width: calc(100% - 25px);">
                  Search
                </span>
              </button>
            </div>
            <SalesForceRuns
              ref="salesForceRuns"
              v-if="reportType === 'Salesforce'"
              :search="searchVal"
              :refresh="refresh"
              @hasRunningJob="(value) => disableNewJobs = value"
            />
            <JobsRuns class="jobs-container-content-body" :search="searchVal" :refresh="refresh" :filterVal="filterVal" @noRecordsFound="$event => jobsHaveNoRecords = $event" v-if="reportType === 'jobs'" />
          </div>
      </div>
    </div>
    <div v-else>
      <div class="has-text-centered">
        <h1 class="is-size-6">Please choose an option from the sidebar menu</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SalesForceRuns from '@/components/jobs/SalesForceRuns.vue'
import JobsRuns from '@/components/jobs/JobsRuns.vue'
import _ from 'lodash'
import Select from '@/components/partials/dropdowns/buttonDropdown.vue'
import IconDropdown from '@/components/partials/dropdowns/IconDropdown.vue'
let pageName = 'Staff Dashboard'

export default {
  components: {
    SalesForceRuns,
    JobsRuns,
    Select,
    IconDropdown
  },
  data () {
    return {
      pageName: pageName,
      salesForceRecords: [],
      reportType: 'Salesforce',
      isLive: true,
      refresh: false,
      requestNew: false,
      importType: 'both',
      disableNewJobs: false,
      filterStyle: 'text-align: left!important; justify-content: flex-start!important;',
      filterOptions: [{
        name: 'All',
        value: '',
        action: () => this.applyFilter('')
      }, {
        name: 'Pending Jobs',
        value: 'pending',
        action: () => this.applyFilter('')
      },
      {
        name: 'Running Jobs',
        value: 'running',
        action: () => this.applyFilter('')
      },
      {
        name: 'Completed Jobs',
        value: 'completed',
        action: () => this.applyFilter('')
      },
      {
        name: 'Failed Jobs',
        value: 'failed',
        action: () => this.applyFilter('')
      }],
      search: '',
      searchVal: '',
      filterVal: '',
      jobsHaveNoRecords: false,
      importOptions: [
        {
          name: 'Salesforce Import',
          action: () => this.performNewRequest('salesforce')
        },
        {
          name: 'Financials Import',
          action: () => this.performNewRequest('financials')
        },
        {
          name: 'Both',
          action: () => this.performNewRequest('both')
        }
      ]
    }
  },
  mounted () {
    const that = this

    window.addEventListener('resize', (event) => {
      that.doRefresh()
    })
  },
  computed: {
    ...mapState(['activeSite', 'clientSession']),
    pageTitle () {
      return (pageName + ' - ' + this.activeSite.displayName)
    },
    compFilterOptions () {
      return this.filterOptions.map(option => {
        return {
          ...option,
          action: () => this.applyFilter(option.value),
          style: this.filterStyle
        }
      })
    },
    selectedFilter () {
      return this.filterOptions.find(option => option.value === this.filterVal).name
    }
  },
  watch: {
    search (val) {
      // _.debounce(() => {
      //   this.searchVal = val

      //   if (val === '') {
      //     this.doRefresh()
      //   }
      // }, 250)()
    }
  },
  methods: {
    performSearch () {
      _.debounce(() => {
        this.searchVal = this.search
      }, 250)()
    },
    applyFilter (value) {
      this.filterVal = value
      this.refresh = true

      setTimeout(() => {
        this.refresh = false
      }, 1000)
    },
    doRefresh () {
      this.refresh = true

      setTimeout(() => {
        this.refresh = false
      }, 1000)
    },
    performNewRequest (type) {
      this.disableNewJobs = true
      this.$refs.salesForceRuns.triggerNewImport(type)

      setTimeout(() => {
        this.disableNewJobs = false
      }, 1000)
    },
    onRequestLocked (value) {
      this.nextTick(() => {
        this.disableNewJobs = true
      })
    }
  }
}
</script>

<style scoped>
.ios-toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  height: 24px;
}

.ios-toggle__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ios-toggle__label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #999;
  border-radius: 20px;
  position: relative;

  width: 100%;
}

button.btn.btn-info {
  background-color: #7fb942;
  border-color: #7fb942;
  color: #fff;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 2.5px !important;
  inset: 0px !important;
}

.ios-toggle__label:active {
  border-color: #666;
}

.ios-toggle__inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.ios-toggle__inner:before,
.ios-toggle__inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 14px!important;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.ios-toggle__inner:before {
  content: attr(data-yes);
  padding-left: 10px;
  background-color: #7fb942;
  color: #fff;
}

.ios-toggle__inner:after {
  content: attr(data-no);
  padding-right: 10px;
  background-color: whitesmoke;
  color: #fff;
  text-align: right;
}

.ios-toggle__input:checked + .ios-toggle__label .ios-toggle__inner {
  margin-left: 0;
}

.ios-toggle__switch {
  display: block;
  width: 18px;
  aspect-ratio: 1/1;
  margin-top: 1px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  border: 2px solid #999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.ios-toggle__input:checked + .ios-toggle__label .ios-toggle__switch {
  right: 0px;
}

.jobs-container {
  width: 75%;
  min-width: 900px;
  height: calc(100vh - 100px - 70px - 32.4px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

@media screen and (max-width: 1280px) {
  .jobs-container {
    width: 100%;
    min-width: auto; /* Remove the minimum width constraint */
  }
}

.jobs-container--block {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

@media (min-width: 1920px) {
  .jobs-container--block {
    width: 100%;
  }
}

@media (min-width: 1500px) {
  .jobs-container--block {
    width: 100%;
  }
}

.jobs-container--header {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  background-color: #eee;
  width: 100%;
  padding: .5rem;

  position: relative;

  display: flex;
  border: 1px solid #dbdbdb;
}

.jobs-container--header--tools {
  position: absolute;
  right: .5rem;
  bottom: 0;

  height: 45px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.jobs-container--header--tools a {
  margin: 0 .5rem;
  cursor: pointer;
}

.jobs-container--header--tools a i {
  font-size: 1.25rem!important;
}

.jobs-container--header > span {
  width: 100%;
  margin: 0;
}

.jobs-container-tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  position: relative;

  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #ededed;
  z-index: 40;
  margin-bottom: -1px;
}

.jobs-container-content-search {
  width: 100%;
  padding: 1rem;
  background-color: whitesmoke;
  position: sticky;
  top: 0;
  position: relative;
    z-index: revert-layer;
  display: flex;
}

.jobs-container-tabs--tools {
  position: absolute;
  right: .5rem;

  bottom: -2px;

  height: 45px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.jobs-container-tabs--tools a {
  margin: 0 .5rem;
  cursor: pointer;
}

.jobs-container-tabs--tools a:last-of-type {
  margin-right: 0;
}

.jobs-container-tabs--tools a i {
  font-size: 1.25rem!important;
}

.jobs-container-tabs > div.active {
  font-weight: bold;
}

.jobs-container-tabs--tab {
  padding: .5rem;
  cursor: pointer;
  width: auto;
  background-color: #f7f7f7;
  border: 1px solid #f1f1f1;
  margin-bottom: -1px;
  margin-left: -.5px;
}

.jobs-container-tabs--tab.is-active {
  font-weight: bold;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-bottom: 0;
  z-index: 50;
}

.jobs-container--content {
  width: 100%;
  height: calc(100% - 41.4px)!important;
  overflow-y: auto;

  border: 1px solid #ededed;

  position: relative;
}

thead {
  background-color: #f7f7f7!important;
  position: sticky!important;
  top: 0!important;
}

.columns .column {
  padding: 0;
  width: 100%;
}
.block {
  width: 100%!important;
}

.block > span {
  width: 100%;
  padding: 0px 0px 7px 0px;
  border-bottom: solid 1px lightgray;
  background-color: #eeeeee;
}
</style>

<style scoped>

thead tr {
  height: 34px!important;
}

tr {
  max-height: 35px!important; height: 35px!important; overflow: hidden!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr td {
  max-height: 35px!important;
  height: 35px!important;
  overflow: hidden!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

tbody tr td {
  max-height: 35px!important;
  height: 35px!important;
  overflow: hidden!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.name-container {
  max-width: calc(365px)!important;
}

.name {
  height: 35px!important;
  max-height: 35px!important;
  width: calc(100%)!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #363636!important;
}

#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}

.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.bcc-color{
  color: #7fb942 !important;
}
.grid-header-controls {
  display: inline-block;
}
.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.visible {
  visibility: hidden;
}

.jobs-container-content-body {
  position: relative;
  z-index: 50;
}
</style>
